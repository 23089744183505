import "./index.scss"
import { DEFAULT_CCPA, CCPA_CONFIG } from "../config/ccpaConfig"

export const initCCPA = async (config) => {
  let configObj = validateConfig(config);
  
  await processCCPA(configObj);

}

const validateConfig = (config) => {
  const eulaAppId = config && config.EulaAppId ? config.EulaAppId : CCPA_CONFIG.EULA_APP_ID;
  const ccpaVersion = config && config.Version ? config.Version : CCPA_CONFIG.CCPA_VERSION;
  let ccpaUrl = composeCCPAUrl(eulaAppId, ccpaVersion);
  let configObj = {
    "CookieDomain": config && config.CookieDomain ? config.CookieDomain : CCPA_CONFIG.COOKIE_DOMAIN,
    "CookieName": config && config.CookieName ? config.CookieName : CCPA_CONFIG.COOKIE_NAME,
    "ExpiryInDays": config && config.ExpiryInDays ? config.ExpiryInDays : CCPA_CONFIG.EXPIRY_IN_DAYS,
    "CCPAUrl": ccpaUrl
  }

  return configObj;
}

const composeCCPAUrl = (eulaAppId, version) => {
  let  ccpaUrlSuffix = version === "latest" ? `${eulaAppId}.txt` : `${eulaAppId}_${version}.txt`;
  return `${CCPA_CONFIG.CCPA_URL_BASEPATH}${eulaAppId}/${version}/${ccpaUrlSuffix}`;
}

const fetchCCPAcontent = async (config) => {
  let ccpaContent = DEFAULT_CCPA;
  try {
    const res = await fetch(config.CCPAUrl, {
      method: "GET"
    });

    if (res.ok) {
      const ccpaText = await res.text();
      if (ccpaText) {
        ccpaContent = ccpaText;
      }
    } 
  } catch (error) {
    console.log("error while fetching ccpa", error);
  }
  return ccpaContent;
}

const hasCcpaAccepted = (cookieName) => {
  let hasAccepted = getCookie(cookieName);
  return !!(hasAccepted);
}

const loadCCPAContent = async (config) => {
  let ccpa = await fetchCCPAcontent(config);
  let ccpaElement = document.getElementById("fa-ccpa-notice")
  if (!ccpaElement) {
    let e = document.createElement('div');
    e.innerHTML = ccpaDecodeHtml(ccpa);

    document.body.appendChild(e.childNodes[0]);
  }
}

const processCCPA = async (config) => {
  let hasAccepted = hasCcpaAccepted(config.CookieName);
  console.log("Has-CCPA-Accepted", hasAccepted);
  if (!hasAccepted) {
    await loadCCPAContent(config);
    let ccpaGotItButton = document.querySelector('#fa-ccpa-button');
    let ccpaModal = document.querySelector('.fa-ccpa-notice');
    ccpaModal.classList.remove('fa-ccpa-display-none');
    ccpaGotItButton.addEventListener('click', function () {
      setCookie(config.CookieName, 'true', config.CookieDomain, config.ExpiryInDays);
      ccpaModal.classList.add('fa-ccpa-display-none');
    }
    );
  }
}

const getCookie = (name) => {
  const splitCookies = document.cookie.split(';');
  const searchString = `${name}=`;
  for (const element of splitCookies) {
    const currentCookie = element.trim();
    if (currentCookie.indexOf(searchString) === 0) {
      return currentCookie.substring(searchString.length, currentCookie.length);
    }
  }
  return undefined;
}

const setCookie = (name, value, domain, expiryInDays) => {
  let baseDomain = domain ? `; domain=${domain}` : "";
  let d = new Date();
  d.setTime(d.getTime() + (expiryInDays * 24 * 60 * 60 * 1000));
  let expires = "; expires=" + d.toGMTString();
  document.cookie = `${name}=${value}${baseDomain}${expires}; secure=true; samesite=Lax; path=/`;
}

const ccpaDecodeHtml = (encodedHtmlTxt) => {
  let e = document.createElement('textarea');
  e.innerHTML = decodeURIComponent(encodedHtmlTxt);
  return e.value;
}