// Babel Polyfill doesn't handle element.prepend.
// https:stackoverflow.com/questions/55243446/webpack-babel-prepend-ie-11
// This polyfill taken from: https://developer.mozilla.org/en-US/docs/Web/API/ParentNode/prepend
;(function(arr) {
  arr.forEach(function(item) {
    if (item.hasOwnProperty("prepend")) {
      return
    }

    Object.defineProperty(item, "prepend", {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function prepend() {
        let argArr = Array.prototype.slice.call(arguments),
          docFrag = document.createDocumentFragment()

        argArr.forEach(function(argItem) {
          let isNode = argItem instanceof Node
          docFrag.appendChild(
            isNode ? argItem : document.createTextNode(String(argItem))
          )
        })

        this.insertBefore(docFrag, this.firstChild)
      }
    })
  })
})([Element.prototype, Document.prototype, DocumentFragment.prototype])
